import { IHttpClient } from '@bridge/IHttpClient';
import { ILogger } from '@bridge/ILogger';
import { IDevice } from '@/bridge/IDevice';
import { IMetrics } from '@bridge/IMetrics';
import {
  WsBrokerAPI,
  WSBrokerServiceConstants,
} from '@/core/wsbroker/Constants';
import { ServiceUtility } from '@/core/wsbroker/ServiceUtility';
import {
  IAllocateResourceSuccessResponse,
  IAuthenticateRequest,
  IGetResourcesSuccessResponse,
  IPerformResourceActionRequest,
  IPerformResourceActionResponse,
  IGetHeartBeatInfoSuccessResponse,
} from '@/core/wsbroker/types';
import {
  AuthCapability,
  PlatformType,
} from '@bridge/types/SoloRTCChannelTypes';
import { EndPointType, IRegion } from '@bridge/types/RegionTypes';
import { HttpRequestHeader } from '../http/HttpClient';
import { ISessionManager } from '@bridge/ISessionManager';
import { HttpsSettings } from '@bridge/types/SessionTypes';
import { MetricName, Operation } from '@bridge/types/MetricTypes';
import { IPv4FallbackHelper } from '@/bridge/utility/ipv4FallbackHelper';

export class WSBrokerService {
  private readonly httpClient: IHttpClient<any>;
  private readonly logger: ILogger;
  private readonly device: IDevice;
  private readonly metrics: IMetrics;
  private readonly sessionManager: ISessionManager;
  constructor(
    httpClient: IHttpClient<any>,
    logger: ILogger,
    device: IDevice,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.httpClient = httpClient;
    this.logger = logger;
    this.device = device;
    this.metrics = metrics;
    this.sessionManager = sessionManager;
  }

  async getHeartBeatInfo(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetHeartBeatInfo
    );
    this.logger.info(
      `Calling WSBroker:GetHeartBeatInfo API with arguments: regCode::${regCode}}`
    );

    const requestBody = ServiceUtility.getHeartBeatInfoRequestBody(
      authToken,
      sessionId
    );

    return await this.makeRequestWithRetry(
      region,
      headers,
      requestBody,
      WsBrokerAPI.GetHeartBeatInfo,
      Operation.GetHeartbeatInfo
    );
  }

  async fetchAuthInfo(
    regCode: string,
    isConnectionAlias: boolean,
    region: IRegion,
    clientVersion: string,
    clientName: string,
    platformType: PlatformType,
    authCapabilities: AuthCapability[],
    codeChallenge: string
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetAuthInfo
    );
    this.logger.info(
      `Retrieving authentication context for regCode:${regCode}, device:${this.device.getDeviceUUID()}, version:${clientVersion} in region:${
        region.endpoint
      }`
    );
    const requestBody = ServiceUtility.getAuthInfoRequestBody({
      regCode,
      isConnectionAlias,
      clientVersion,
      clientName,
      platformType,
      authCapabilities,
      vendorName: this.device.getVendorName(),
      modelNumber: this.device.getModelNumber(),
      deviceUUID: this.device.getDeviceUUID(),
      codeChallenge,
    });

    return await this.makeRequestWithRetry(
      region,
      headers,
      requestBody,
      WsBrokerAPI.GetAuthInfo,
      Operation.GetAuthInfo
    );
  }

  async authenticate(
    regCode: string,
    region: IRegion,
    authenticateRequest: IAuthenticateRequest
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.Authenticate
    );

    authenticateRequest.Version = WSBrokerServiceConstants.ApiVersion;
    return await this.makeRequestWithRetry(
      region,
      headers,
      authenticateRequest,
      WsBrokerAPI.Authenticate,
      Operation.Authenticate
    );
  }

  async getResources(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.GetResources
    );

    this.logger.info(
      `Retrieving all resources for registration code:${regCode} device:${this.device.getDeviceUUID()} and session:${sessionId} and region:${
        region.endpoint
      }`
    );

    const requestBody = ServiceUtility.getResourcesRequestBody(
      authToken,
      sessionId
    );
    return await this.makeRequestWithRetry(
      region,
      headers,
      requestBody,
      WsBrokerAPI.GetResources,
      Operation.GetResources
    );
  }

  async allocateResource(
    authToken: string,
    sessionId: string,
    resourceId: string,
    resourceProtocol: string,
    regCode: string,
    platformType: PlatformType,
    region: IRegion
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.PerformResourceAction
    );

    this.logger.info(
      `Allocating workspace region with registration code:${regCode}, device:${this.device.getDeviceUUID()}, session:${sessionId}, id:${resourceId}, protocol:${resourceProtocol}, region:${
        region.endpoint
      }`
    );

    const requestBody = ServiceUtility.getAllocateResourceRequestBody(
      sessionId,
      authToken,
      resourceId,
      resourceProtocol,
      platformType,
      this.device.getDeviceUUID()
    );

    return await this.makeRequestWithRetry(
      region,
      headers,
      requestBody,
      WsBrokerAPI.AllocateResource,
      Operation.SessionProvision
    );
  }

  async performResourceAction(
    regCode: string,
    region: IRegion,
    requestBody: IPerformResourceActionRequest
  ) {
    const headers = ServiceUtility.getWsBrokerHttpHeaders(
      WsBrokerAPI.PerformResourceAction
    );

    this.logger.info(
      `Modifying state for workspace with registration code:${regCode} for region:${
        region.endpoint
      } on device:${this.device.getDeviceUUID()}, with action:${JSON.stringify(
        requestBody
      )}`
    );

    return await this.makeRequestWithRetry(
      region,
      headers,
      requestBody,
      WsBrokerAPI.PerformResourceAction,
      Operation.PerformResourceAction
    );
  }

  async makeRequestWithRetry(
    region: IRegion,
    headers: HttpRequestHeader,
    requestBody: any,
    apiEndpoint: WsBrokerAPI,
    operation: Operation
  ) {
    this.logger.info(`Trying to get HTTP Config for endpoint URL`);
    const httpsSettings = this.sessionManager.get('httpsSettings');
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(
      region,
      headers,
      httpsSettings
    );

    this.logger.info(
      `Trying to send request using ${httpsSettings.brokerEndpointType} endpoint URL: ${httpConfig.baseURL}`
    );

    try {
      const { data } = await this.httpClient.post('/', requestBody, httpConfig);
      return this.brokerResponseData(data, apiEndpoint);
    } catch (error) {
      // Toggle the endpoint type
      const transformError = ServiceUtility.transformServiceError(
        error,
        apiEndpoint
      );
      if (
        httpsSettings.brokerEndpointType === EndPointType.IPv6 &&
        IPv4FallbackHelper.shouldFallbacktoIPv4(transformError)
      ) {
        this.logger.info(`Request failed. Trying IPV4 endpoint type.`);
        this.metrics.emitWithValueAndReason(
          operation,
          MetricName.IPv6Fallback,
          1,
          error
        );
        httpsSettings.brokerEndpointType = EndPointType.IPv4;
        return await this.makeRequestwithIP4(
          region,
          headers,
          requestBody,
          apiEndpoint,
          httpsSettings
        );
      } else {
        throw ServiceUtility.transformServiceError(error, apiEndpoint);
      }
    }
  }

  async makeRequestwithIP4(
    region: IRegion,
    headers: HttpRequestHeader,
    requestBody: any,
    apiEndpoint: WsBrokerAPI,
    httpsSettings: HttpsSettings
  ) {
    const httpConfig = ServiceUtility.getWsBrokerHttpConfig(
      region,
      headers,
      httpsSettings
    );

    this.logger.warn(
      `Retrying with ${httpsSettings.brokerEndpointType} endpoint URL: ${httpConfig.baseURL}`
    );
    try {
      const { data } = await this.httpClient.post('/', requestBody, httpConfig);
      console.log(`TESTING broker endPoint : ${apiEndpoint}      `);
      console.log(`TESTING broker response : ${data}      `);
      // Update the session with the new endpoint type
      this.sessionManager.set({ httpsSettings });
      return this.brokerResponseData(data, apiEndpoint);
    } catch (alternativeError) {
      this.logger.error(
        `Request failed on both endpoint types: ${
          (alternativeError as Error).message
        }`
      );
      throw ServiceUtility.transformServiceError(alternativeError, apiEndpoint);
    }
  }

  private brokerResponseData(data: any, apiEndpoint: WsBrokerAPI) {
    switch (apiEndpoint) {
      case WsBrokerAPI.GetHeartBeatInfo:
        return data as IGetHeartBeatInfoSuccessResponse;
      case WsBrokerAPI.GetResources:
        return data as IGetResourcesSuccessResponse;
      case WsBrokerAPI.AllocateResource:
        return data as IAllocateResourceSuccessResponse;
      case WsBrokerAPI.PerformResourceAction:
        return data as IPerformResourceActionResponse;
      default:
        return data;
    }
  }
}
